import React from "react";
import { Page } from "../../../components/Page";
import { Title } from "../../../components/Title";
import { Content } from "../../../components/Content";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const HelsinkiPage = () => (
  <Page language="en" page="Helsinki">
    <Row>
      <Col>
        <Title>To sign up for warnings:</Title>

        <Content>
          Send a text message to number 16130 from the phone where you want to
          receive the message:
        </Content>

        <Content as="ol">
          <li>
            At the beginning, write words <b>warning helsinki</b>
          </li>
          <li>
            At the end, write <b>right away, 6, 7, 8 or 9</b>, whether you want
            the warning on the slipperiness, created during night, right away
            after creation of the warning or at 6 am, 7 am, 8 am or 9 am
          </li>
          <li>
            For example, if you want a warning on the slipperiness created
            during night in the morning at 7 am, send a text message:{" "}
            <b>warning helsinki 7</b>
          </li>
        </Content>

        <Content>
          Your sign in will also remain in force in the future winter seasons.
          If you want to disable the service, send a text message{" "}
          <b>warning close</b>
          to number <b>16130</b>.
        </Content>
      </Col>
    </Row>
  </Page>
);

export default HelsinkiPage;
